// source: hotel/booking.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var hotel_base_pb = require('../hotel/base_pb.js');
goog.object.extend(proto, hotel_base_pb);
var validate_validate_pb = require('../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.hotel.BookingDetail', null, global);
goog.exportSymbol('proto.hotel.BookingPrice', null, global);
goog.exportSymbol('proto.hotel.BookingRoom', null, global);
goog.exportSymbol('proto.hotel.Company', null, global);
goog.exportSymbol('proto.hotel.InvoicingInformation', null, global);
goog.exportSymbol('proto.hotel.OccupancyInfo', null, global);
goog.exportSymbol('proto.hotel.PaymentInfo', null, global);
goog.exportSymbol('proto.hotel.PaymentInfo.PaymentMethod', null, global);
goog.exportSymbol('proto.hotel.Receiver', null, global);
goog.exportSymbol('proto.hotel.RoomHolder', null, global);
goog.exportSymbol('proto.hotel.RoomOccupancyRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.PaymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.PaymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.PaymentInfo.displayName = 'proto.hotel.PaymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.PaymentInfo.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.PaymentInfo.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.PaymentInfo.PaymentMethod.displayName = 'proto.hotel.PaymentInfo.PaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BookingDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.BookingDetail.repeatedFields_, null);
};
goog.inherits(proto.hotel.BookingDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BookingDetail.displayName = 'proto.hotel.BookingDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.OccupancyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.OccupancyInfo.repeatedFields_, null);
};
goog.inherits(proto.hotel.OccupancyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.OccupancyInfo.displayName = 'proto.hotel.OccupancyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.RoomOccupancyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.RoomOccupancyRequest.repeatedFields_, null);
};
goog.inherits(proto.hotel.RoomOccupancyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.RoomOccupancyRequest.displayName = 'proto.hotel.RoomOccupancyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.RoomHolder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.RoomHolder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.RoomHolder.displayName = 'proto.hotel.RoomHolder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BookingRoom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.BookingRoom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BookingRoom.displayName = 'proto.hotel.BookingRoom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BookingPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.BookingPrice.repeatedFields_, null);
};
goog.inherits(proto.hotel.BookingPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BookingPrice.displayName = 'proto.hotel.BookingPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Company.displayName = 'proto.hotel.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Receiver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Receiver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Receiver.displayName = 'proto.hotel.Receiver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.InvoicingInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.InvoicingInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.InvoicingInformation.displayName = 'proto.hotel.InvoicingInformation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.PaymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.PaymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.PaymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PaymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    method: (f = msg.getMethod()) && proto.hotel.PaymentInfo.PaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.PaymentInfo}
 */
proto.hotel.PaymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.PaymentInfo;
  return proto.hotel.PaymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.PaymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.PaymentInfo}
 */
proto.hotel.PaymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeeAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 3:
      var value = new proto.hotel.PaymentInfo.PaymentMethod;
      reader.readMessage(value,proto.hotel.PaymentInfo.PaymentMethod.deserializeBinaryFromReader);
      msg.setMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.PaymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.PaymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.PaymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PaymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMethod();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.hotel.PaymentInfo.PaymentMethod.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.PaymentInfo.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.PaymentInfo.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.PaymentInfo.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PaymentInfo.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.PaymentInfo.PaymentMethod}
 */
proto.hotel.PaymentInfo.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.PaymentInfo.PaymentMethod;
  return proto.hotel.PaymentInfo.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.PaymentInfo.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.PaymentInfo.PaymentMethod}
 */
proto.hotel.PaymentInfo.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.PaymentInfo.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.PaymentInfo.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.PaymentInfo.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PaymentInfo.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.hotel.PaymentInfo.PaymentMethod.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PaymentInfo.PaymentMethod} returns this
 */
proto.hotel.PaymentInfo.PaymentMethod.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.PaymentInfo.PaymentMethod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PaymentInfo.PaymentMethod} returns this
 */
proto.hotel.PaymentInfo.PaymentMethod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double fee_amount = 1;
 * @return {number}
 */
proto.hotel.PaymentInfo.prototype.getFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PaymentInfo} returns this
 */
proto.hotel.PaymentInfo.prototype.setFeeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double total_price = 2;
 * @return {number}
 */
proto.hotel.PaymentInfo.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PaymentInfo} returns this
 */
proto.hotel.PaymentInfo.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional PaymentMethod method = 3;
 * @return {?proto.hotel.PaymentInfo.PaymentMethod}
 */
proto.hotel.PaymentInfo.prototype.getMethod = function() {
  return /** @type{?proto.hotel.PaymentInfo.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.hotel.PaymentInfo.PaymentMethod, 3));
};


/**
 * @param {?proto.hotel.PaymentInfo.PaymentMethod|undefined} value
 * @return {!proto.hotel.PaymentInfo} returns this
*/
proto.hotel.PaymentInfo.prototype.setMethod = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.PaymentInfo} returns this
 */
proto.hotel.PaymentInfo.prototype.clearMethod = function() {
  return this.setMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.PaymentInfo.prototype.hasMethod = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.BookingDetail.repeatedFields_ = [4,15,16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BookingDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BookingDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BookingDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hotelInfo: (f = msg.getHotelInfo()) && hotel_base_pb.HotelBasicInfo.toObject(includeInstance, f),
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    proto.hotel.BookingRoom.toObject, includeInstance),
    invoicingInfo: (f = msg.getInvoicingInfo()) && proto.hotel.InvoicingInformation.toObject(includeInstance, f),
    totalNights: jspb.Message.getFieldWithDefault(msg, 6, 0),
    priceInfo: (f = msg.getPriceInfo()) && proto.hotel.BookingPrice.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pendingDeadline: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cancelReason: jspb.Message.getFieldWithDefault(msg, 12, ""),
    bedOption: (f = msg.getBedOption()) && hotel_base_pb.BedOption.toObject(includeInstance, f),
    source: jspb.Message.getFieldWithDefault(msg, 14, ""),
    checkInInstructionsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    cancelPoliciesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    amenitiesList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    checkInDate: jspb.Message.getFieldWithDefault(msg, 18, ""),
    checkInTime: jspb.Message.getFieldWithDefault(msg, 19, ""),
    checkOutDate: jspb.Message.getFieldWithDefault(msg, 20, ""),
    checkOutTime: jspb.Message.getFieldWithDefault(msg, 21, ""),
    specialRequest: jspb.Message.getFieldWithDefault(msg, 22, ""),
    destination: (f = msg.getDestination()) && hotel_base_pb.Destination.toObject(includeInstance, f),
    pnrCode: jspb.Message.getFieldWithDefault(msg, 24, ""),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    agentCode: jspb.Message.getFieldWithDefault(msg, 26, ""),
    ownerName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    paymentInfo: (f = msg.getPaymentInfo()) && proto.hotel.PaymentInfo.toObject(includeInstance, f),
    partnerShopId: jspb.Message.getFieldWithDefault(msg, 29, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BookingDetail}
 */
proto.hotel.BookingDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BookingDetail;
  return proto.hotel.BookingDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BookingDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BookingDetail}
 */
proto.hotel.BookingDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 3:
      var value = new hotel_base_pb.HotelBasicInfo;
      reader.readMessage(value,hotel_base_pb.HotelBasicInfo.deserializeBinaryFromReader);
      msg.setHotelInfo(value);
      break;
    case 4:
      var value = new proto.hotel.BookingRoom;
      reader.readMessage(value,proto.hotel.BookingRoom.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    case 5:
      var value = new proto.hotel.InvoicingInformation;
      reader.readMessage(value,proto.hotel.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInfo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNights(value);
      break;
    case 7:
      var value = new proto.hotel.BookingPrice;
      reader.readMessage(value,proto.hotel.BookingPrice.deserializeBinaryFromReader);
      msg.setPriceInfo(value);
      break;
    case 8:
      var value = /** @type {!proto.hotel.BookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPendingDeadline(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCancelReason(value);
      break;
    case 13:
      var value = new hotel_base_pb.BedOption;
      reader.readMessage(value,hotel_base_pb.BedOption.deserializeBinaryFromReader);
      msg.setBedOption(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addCheckInInstructions(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addCancelPolicies(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addAmenities(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckInDate(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckInTime(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOutDate(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOutTime(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialRequest(value);
      break;
    case 23:
      var value = new hotel_base_pb.Destination;
      reader.readMessage(value,hotel_base_pb.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setPnrCode(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerName(value);
      break;
    case 28:
      var value = new proto.hotel.PaymentInfo;
      reader.readMessage(value,proto.hotel.PaymentInfo.deserializeBinaryFromReader);
      msg.setPaymentInfo(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerShopId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BookingDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BookingDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BookingDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHotelInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hotel_base_pb.HotelBasicInfo.serializeBinaryToWriter
    );
  }
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.hotel.BookingRoom.serializeBinaryToWriter
    );
  }
  f = message.getInvoicingInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.hotel.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getTotalNights();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPriceInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.hotel.BookingPrice.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPendingDeadline();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCancelReason();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBedOption();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      hotel_base_pb.BedOption.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCheckInInstructionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getCancelPoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getCheckInDate();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCheckInTime();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCheckOutDate();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCheckOutTime();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSpecialRequest();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      hotel_base_pb.Destination.serializeBinaryToWriter
    );
  }
  f = message.getPnrCode();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getOwnerName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getPaymentInfo();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.hotel.PaymentInfo.serializeBinaryToWriter
    );
  }
  f = message.getPartnerShopId();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_code = 2;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional HotelBasicInfo hotel_info = 3;
 * @return {?proto.hotel.HotelBasicInfo}
 */
proto.hotel.BookingDetail.prototype.getHotelInfo = function() {
  return /** @type{?proto.hotel.HotelBasicInfo} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.HotelBasicInfo, 3));
};


/**
 * @param {?proto.hotel.HotelBasicInfo|undefined} value
 * @return {!proto.hotel.BookingDetail} returns this
*/
proto.hotel.BookingDetail.prototype.setHotelInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearHotelInfo = function() {
  return this.setHotelInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingDetail.prototype.hasHotelInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated BookingRoom rooms = 4;
 * @return {!Array<!proto.hotel.BookingRoom>}
 */
proto.hotel.BookingDetail.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.hotel.BookingRoom>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.BookingRoom, 4));
};


/**
 * @param {!Array<!proto.hotel.BookingRoom>} value
 * @return {!proto.hotel.BookingDetail} returns this
*/
proto.hotel.BookingDetail.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.BookingRoom=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingRoom}
 */
proto.hotel.BookingDetail.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.BookingRoom, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};


/**
 * optional InvoicingInformation invoicing_info = 5;
 * @return {?proto.hotel.InvoicingInformation}
 */
proto.hotel.BookingDetail.prototype.getInvoicingInfo = function() {
  return /** @type{?proto.hotel.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, proto.hotel.InvoicingInformation, 5));
};


/**
 * @param {?proto.hotel.InvoicingInformation|undefined} value
 * @return {!proto.hotel.BookingDetail} returns this
*/
proto.hotel.BookingDetail.prototype.setInvoicingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearInvoicingInfo = function() {
  return this.setInvoicingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingDetail.prototype.hasInvoicingInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 total_nights = 6;
 * @return {number}
 */
proto.hotel.BookingDetail.prototype.getTotalNights = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setTotalNights = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional BookingPrice price_info = 7;
 * @return {?proto.hotel.BookingPrice}
 */
proto.hotel.BookingDetail.prototype.getPriceInfo = function() {
  return /** @type{?proto.hotel.BookingPrice} */ (
    jspb.Message.getWrapperField(this, proto.hotel.BookingPrice, 7));
};


/**
 * @param {?proto.hotel.BookingPrice|undefined} value
 * @return {!proto.hotel.BookingDetail} returns this
*/
proto.hotel.BookingDetail.prototype.setPriceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearPriceInfo = function() {
  return this.setPriceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingDetail.prototype.hasPriceInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional BookingStatus status = 8;
 * @return {!proto.hotel.BookingStatus}
 */
proto.hotel.BookingDetail.prototype.getStatus = function() {
  return /** @type {!proto.hotel.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.hotel.BookingStatus} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.hotel.BookingDetail.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 expired_at = 10;
 * @return {number}
 */
proto.hotel.BookingDetail.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 pending_deadline = 11;
 * @return {number}
 */
proto.hotel.BookingDetail.prototype.getPendingDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setPendingDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string cancel_reason = 12;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getCancelReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setCancelReason = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional BedOption bed_option = 13;
 * @return {?proto.hotel.BedOption}
 */
proto.hotel.BookingDetail.prototype.getBedOption = function() {
  return /** @type{?proto.hotel.BedOption} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.BedOption, 13));
};


/**
 * @param {?proto.hotel.BedOption|undefined} value
 * @return {!proto.hotel.BookingDetail} returns this
*/
proto.hotel.BookingDetail.prototype.setBedOption = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearBedOption = function() {
  return this.setBedOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingDetail.prototype.hasBedOption = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string source = 14;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated string check_in_instructions = 15;
 * @return {!Array<string>}
 */
proto.hotel.BookingDetail.prototype.getCheckInInstructionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setCheckInInstructionsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.addCheckInInstructions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearCheckInInstructionsList = function() {
  return this.setCheckInInstructionsList([]);
};


/**
 * repeated string cancel_policies = 16;
 * @return {!Array<string>}
 */
proto.hotel.BookingDetail.prototype.getCancelPoliciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setCancelPoliciesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.addCancelPolicies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearCancelPoliciesList = function() {
  return this.setCancelPoliciesList([]);
};


/**
 * repeated string amenities = 17;
 * @return {!Array<string>}
 */
proto.hotel.BookingDetail.prototype.getAmenitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.addAmenities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};


/**
 * optional string check_in_date = 18;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getCheckInDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setCheckInDate = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string check_in_time = 19;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getCheckInTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setCheckInTime = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string check_out_date = 20;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getCheckOutDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setCheckOutDate = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string check_out_time = 21;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getCheckOutTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setCheckOutTime = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string special_request = 22;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getSpecialRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setSpecialRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional Destination destination = 23;
 * @return {?proto.hotel.Destination}
 */
proto.hotel.BookingDetail.prototype.getDestination = function() {
  return /** @type{?proto.hotel.Destination} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.Destination, 23));
};


/**
 * @param {?proto.hotel.Destination|undefined} value
 * @return {!proto.hotel.BookingDetail} returns this
*/
proto.hotel.BookingDetail.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingDetail.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string pnr_code = 24;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getPnrCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setPnrCode = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional bool editable = 25;
 * @return {boolean}
 */
proto.hotel.BookingDetail.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional string agent_code = 26;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string owner_name = 27;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional PaymentInfo payment_info = 28;
 * @return {?proto.hotel.PaymentInfo}
 */
proto.hotel.BookingDetail.prototype.getPaymentInfo = function() {
  return /** @type{?proto.hotel.PaymentInfo} */ (
    jspb.Message.getWrapperField(this, proto.hotel.PaymentInfo, 28));
};


/**
 * @param {?proto.hotel.PaymentInfo|undefined} value
 * @return {!proto.hotel.BookingDetail} returns this
*/
proto.hotel.BookingDetail.prototype.setPaymentInfo = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.clearPaymentInfo = function() {
  return this.setPaymentInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingDetail.prototype.hasPaymentInfo = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string partner_shop_id = 29;
 * @return {string}
 */
proto.hotel.BookingDetail.prototype.getPartnerShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingDetail} returns this
 */
proto.hotel.BookingDetail.prototype.setPartnerShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.OccupancyInfo.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.OccupancyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.OccupancyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.OccupancyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.OccupancyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    chd: jspb.Message.getFieldWithDefault(msg, 4, 0),
    chdAgesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.OccupancyInfo}
 */
proto.hotel.OccupancyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.OccupancyInfo;
  return proto.hotel.OccupancyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.OccupancyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.OccupancyInfo}
 */
proto.hotel.OccupancyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChd(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChdAges(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.OccupancyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.OccupancyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.OccupancyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.OccupancyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdt();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getChd();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getChdAgesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.hotel.OccupancyInfo.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.OccupancyInfo} returns this
 */
proto.hotel.OccupancyInfo.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.hotel.OccupancyInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.OccupancyInfo} returns this
 */
proto.hotel.OccupancyInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 adt = 3;
 * @return {number}
 */
proto.hotel.OccupancyInfo.prototype.getAdt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.OccupancyInfo} returns this
 */
proto.hotel.OccupancyInfo.prototype.setAdt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 chd = 4;
 * @return {number}
 */
proto.hotel.OccupancyInfo.prototype.getChd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.OccupancyInfo} returns this
 */
proto.hotel.OccupancyInfo.prototype.setChd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated int32 chd_ages = 5;
 * @return {!Array<number>}
 */
proto.hotel.OccupancyInfo.prototype.getChdAgesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hotel.OccupancyInfo} returns this
 */
proto.hotel.OccupancyInfo.prototype.setChdAgesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyInfo} returns this
 */
proto.hotel.OccupancyInfo.prototype.addChdAges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.OccupancyInfo} returns this
 */
proto.hotel.OccupancyInfo.prototype.clearChdAgesList = function() {
  return this.setChdAgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.RoomOccupancyRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.RoomOccupancyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.RoomOccupancyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.RoomOccupancyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RoomOccupancyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adults: jspb.Message.getFieldWithDefault(msg, 2, 0),
    childAgesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    holder: (f = msg.getHolder()) && proto.hotel.RoomHolder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.RoomOccupancyRequest}
 */
proto.hotel.RoomOccupancyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.RoomOccupancyRequest;
  return proto.hotel.RoomOccupancyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.RoomOccupancyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.RoomOccupancyRequest}
 */
proto.hotel.RoomOccupancyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdults(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChildAges(values[i]);
      }
      break;
    case 4:
      var value = new proto.hotel.RoomHolder;
      reader.readMessage(value,proto.hotel.RoomHolder.deserializeBinaryFromReader);
      msg.setHolder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.RoomOccupancyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.RoomOccupancyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.RoomOccupancyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RoomOccupancyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdults();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getChildAgesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getHolder();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.hotel.RoomHolder.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.hotel.RoomOccupancyRequest.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.RoomOccupancyRequest} returns this
 */
proto.hotel.RoomOccupancyRequest.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 adults = 2;
 * @return {number}
 */
proto.hotel.RoomOccupancyRequest.prototype.getAdults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.RoomOccupancyRequest} returns this
 */
proto.hotel.RoomOccupancyRequest.prototype.setAdults = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int32 child_ages = 3;
 * @return {!Array<number>}
 */
proto.hotel.RoomOccupancyRequest.prototype.getChildAgesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hotel.RoomOccupancyRequest} returns this
 */
proto.hotel.RoomOccupancyRequest.prototype.setChildAgesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomOccupancyRequest} returns this
 */
proto.hotel.RoomOccupancyRequest.prototype.addChildAges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.RoomOccupancyRequest} returns this
 */
proto.hotel.RoomOccupancyRequest.prototype.clearChildAgesList = function() {
  return this.setChildAgesList([]);
};


/**
 * optional RoomHolder holder = 4;
 * @return {?proto.hotel.RoomHolder}
 */
proto.hotel.RoomOccupancyRequest.prototype.getHolder = function() {
  return /** @type{?proto.hotel.RoomHolder} */ (
    jspb.Message.getWrapperField(this, proto.hotel.RoomHolder, 4));
};


/**
 * @param {?proto.hotel.RoomHolder|undefined} value
 * @return {!proto.hotel.RoomOccupancyRequest} returns this
*/
proto.hotel.RoomOccupancyRequest.prototype.setHolder = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.RoomOccupancyRequest} returns this
 */
proto.hotel.RoomOccupancyRequest.prototype.clearHolder = function() {
  return this.setHolder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.RoomOccupancyRequest.prototype.hasHolder = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.RoomHolder.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.RoomHolder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.RoomHolder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RoomHolder.toObject = function(includeInstance, msg) {
  var f, obj = {
    givenName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    surname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.RoomHolder}
 */
proto.hotel.RoomHolder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.RoomHolder;
  return proto.hotel.RoomHolder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.RoomHolder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.RoomHolder}
 */
proto.hotel.RoomHolder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.RoomHolder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.RoomHolder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.RoomHolder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RoomHolder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSurname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string given_name = 1;
 * @return {string}
 */
proto.hotel.RoomHolder.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomHolder} returns this
 */
proto.hotel.RoomHolder.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string surname = 2;
 * @return {string}
 */
proto.hotel.RoomHolder.prototype.getSurname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomHolder} returns this
 */
proto.hotel.RoomHolder.prototype.setSurname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.hotel.RoomHolder.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomHolder} returns this
 */
proto.hotel.RoomHolder.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.hotel.RoomHolder.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomHolder} returns this
 */
proto.hotel.RoomHolder.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.hotel.RoomHolder.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomHolder} returns this
 */
proto.hotel.RoomHolder.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BookingRoom.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BookingRoom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BookingRoom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingRoom.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    holder: (f = msg.getHolder()) && proto.hotel.RoomHolder.toObject(includeInstance, f),
    occupancy: (f = msg.getOccupancy()) && proto.hotel.OccupancyInfo.toObject(includeInstance, f),
    confirmationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bedOption: (f = msg.getBedOption()) && hotel_base_pb.BedOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BookingRoom}
 */
proto.hotel.BookingRoom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BookingRoom;
  return proto.hotel.BookingRoom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BookingRoom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BookingRoom}
 */
proto.hotel.BookingRoom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.hotel.RoomHolder;
      reader.readMessage(value,proto.hotel.RoomHolder.deserializeBinaryFromReader);
      msg.setHolder(value);
      break;
    case 4:
      var value = new proto.hotel.OccupancyInfo;
      reader.readMessage(value,proto.hotel.OccupancyInfo.deserializeBinaryFromReader);
      msg.setOccupancy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationId(value);
      break;
    case 6:
      var value = new hotel_base_pb.BedOption;
      reader.readMessage(value,hotel_base_pb.BedOption.deserializeBinaryFromReader);
      msg.setBedOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BookingRoom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BookingRoom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BookingRoom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingRoom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHolder();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.hotel.RoomHolder.serializeBinaryToWriter
    );
  }
  f = message.getOccupancy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.hotel.OccupancyInfo.serializeBinaryToWriter
    );
  }
  f = message.getConfirmationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBedOption();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      hotel_base_pb.BedOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.hotel.BookingRoom.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingRoom} returns this
 */
proto.hotel.BookingRoom.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.BookingRoom.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingRoom} returns this
 */
proto.hotel.BookingRoom.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RoomHolder holder = 3;
 * @return {?proto.hotel.RoomHolder}
 */
proto.hotel.BookingRoom.prototype.getHolder = function() {
  return /** @type{?proto.hotel.RoomHolder} */ (
    jspb.Message.getWrapperField(this, proto.hotel.RoomHolder, 3));
};


/**
 * @param {?proto.hotel.RoomHolder|undefined} value
 * @return {!proto.hotel.BookingRoom} returns this
*/
proto.hotel.BookingRoom.prototype.setHolder = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingRoom} returns this
 */
proto.hotel.BookingRoom.prototype.clearHolder = function() {
  return this.setHolder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingRoom.prototype.hasHolder = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OccupancyInfo occupancy = 4;
 * @return {?proto.hotel.OccupancyInfo}
 */
proto.hotel.BookingRoom.prototype.getOccupancy = function() {
  return /** @type{?proto.hotel.OccupancyInfo} */ (
    jspb.Message.getWrapperField(this, proto.hotel.OccupancyInfo, 4));
};


/**
 * @param {?proto.hotel.OccupancyInfo|undefined} value
 * @return {!proto.hotel.BookingRoom} returns this
*/
proto.hotel.BookingRoom.prototype.setOccupancy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingRoom} returns this
 */
proto.hotel.BookingRoom.prototype.clearOccupancy = function() {
  return this.setOccupancy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingRoom.prototype.hasOccupancy = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string confirmation_id = 5;
 * @return {string}
 */
proto.hotel.BookingRoom.prototype.getConfirmationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingRoom} returns this
 */
proto.hotel.BookingRoom.prototype.setConfirmationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional BedOption bed_option = 6;
 * @return {?proto.hotel.BedOption}
 */
proto.hotel.BookingRoom.prototype.getBedOption = function() {
  return /** @type{?proto.hotel.BedOption} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.BedOption, 6));
};


/**
 * @param {?proto.hotel.BedOption|undefined} value
 * @return {!proto.hotel.BookingRoom} returns this
*/
proto.hotel.BookingRoom.prototype.setBedOption = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingRoom} returns this
 */
proto.hotel.BookingRoom.prototype.clearBedOption = function() {
  return this.setBedOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingRoom.prototype.hasBedOption = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.BookingPrice.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BookingPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BookingPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BookingPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    currency: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalRateAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalTaxesAndFees: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    payAtHotelList: jspb.Message.toObjectList(msg.getPayAtHotelList(),
    hotel_base_pb.PayAtHotel.toObject, includeInstance),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    payNow: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    additionalFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    actualPaidAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    discount: (f = msg.getDiscount()) && hotel_base_pb.PriceAdjustmentResponse.toObject(includeInstance, f),
    commission: (f = msg.getCommission()) && hotel_base_pb.PriceAdjustmentResponse.toObject(includeInstance, f),
    totalPayAtHotel: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    payAtHotelCurrency: jspb.Message.getFieldWithDefault(msg, 12, ""),
    surcharge: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BookingPrice}
 */
proto.hotel.BookingPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BookingPrice;
  return proto.hotel.BookingPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BookingPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BookingPrice}
 */
proto.hotel.BookingPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalRateAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxesAndFees(value);
      break;
    case 4:
      var value = new hotel_base_pb.PayAtHotel;
      reader.readMessage(value,hotel_base_pb.PayAtHotel.deserializeBinaryFromReader);
      msg.addPayAtHotel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPayNow(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdditionalFee(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualPaidAmount(value);
      break;
    case 9:
      var value = new hotel_base_pb.PriceAdjustmentResponse;
      reader.readMessage(value,hotel_base_pb.PriceAdjustmentResponse.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    case 10:
      var value = new hotel_base_pb.PriceAdjustmentResponse;
      reader.readMessage(value,hotel_base_pb.PriceAdjustmentResponse.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPayAtHotel(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayAtHotelCurrency(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSurcharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BookingPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BookingPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BookingPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalRateAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalTaxesAndFees();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPayAtHotelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hotel_base_pb.PayAtHotel.serializeBinaryToWriter
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPayNow();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAdditionalFee();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getActualPaidAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      hotel_base_pb.PriceAdjustmentResponse.serializeBinaryToWriter
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      hotel_base_pb.PriceAdjustmentResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalPayAtHotel();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPayAtHotelCurrency();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSurcharge();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional string currency = 1;
 * @return {string}
 */
proto.hotel.BookingPrice.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double total_rate_amount = 2;
 * @return {number}
 */
proto.hotel.BookingPrice.prototype.getTotalRateAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setTotalRateAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_taxes_and_fees = 3;
 * @return {number}
 */
proto.hotel.BookingPrice.prototype.getTotalTaxesAndFees = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setTotalTaxesAndFees = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated PayAtHotel pay_at_hotel = 4;
 * @return {!Array<!proto.hotel.PayAtHotel>}
 */
proto.hotel.BookingPrice.prototype.getPayAtHotelList = function() {
  return /** @type{!Array<!proto.hotel.PayAtHotel>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.PayAtHotel, 4));
};


/**
 * @param {!Array<!proto.hotel.PayAtHotel>} value
 * @return {!proto.hotel.BookingPrice} returns this
*/
proto.hotel.BookingPrice.prototype.setPayAtHotelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.PayAtHotel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.PayAtHotel}
 */
proto.hotel.BookingPrice.prototype.addPayAtHotel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.PayAtHotel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.clearPayAtHotelList = function() {
  return this.setPayAtHotelList([]);
};


/**
 * optional double total_price = 5;
 * @return {number}
 */
proto.hotel.BookingPrice.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double pay_now = 6;
 * @return {number}
 */
proto.hotel.BookingPrice.prototype.getPayNow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setPayNow = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double additional_fee = 7;
 * @return {number}
 */
proto.hotel.BookingPrice.prototype.getAdditionalFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setAdditionalFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double actual_paid_amount = 8;
 * @return {number}
 */
proto.hotel.BookingPrice.prototype.getActualPaidAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setActualPaidAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional PriceAdjustmentResponse discount = 9;
 * @return {?proto.hotel.PriceAdjustmentResponse}
 */
proto.hotel.BookingPrice.prototype.getDiscount = function() {
  return /** @type{?proto.hotel.PriceAdjustmentResponse} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PriceAdjustmentResponse, 9));
};


/**
 * @param {?proto.hotel.PriceAdjustmentResponse|undefined} value
 * @return {!proto.hotel.BookingPrice} returns this
*/
proto.hotel.BookingPrice.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingPrice.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PriceAdjustmentResponse commission = 10;
 * @return {?proto.hotel.PriceAdjustmentResponse}
 */
proto.hotel.BookingPrice.prototype.getCommission = function() {
  return /** @type{?proto.hotel.PriceAdjustmentResponse} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PriceAdjustmentResponse, 10));
};


/**
 * @param {?proto.hotel.PriceAdjustmentResponse|undefined} value
 * @return {!proto.hotel.BookingPrice} returns this
*/
proto.hotel.BookingPrice.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingPrice.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional double total_pay_at_hotel = 11;
 * @return {number}
 */
proto.hotel.BookingPrice.prototype.getTotalPayAtHotel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setTotalPayAtHotel = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string pay_at_hotel_currency = 12;
 * @return {string}
 */
proto.hotel.BookingPrice.prototype.getPayAtHotelCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setPayAtHotelCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double surcharge = 13;
 * @return {number}
 */
proto.hotel.BookingPrice.prototype.getSurcharge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingPrice} returns this
 */
proto.hotel.BookingPrice.prototype.setSurcharge = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Company}
 */
proto.hotel.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Company;
  return proto.hotel.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Company}
 */
proto.hotel.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.hotel.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Company} returns this
 */
proto.hotel.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tax_code = 2;
 * @return {string}
 */
proto.hotel.Company.prototype.getTaxCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Company} returns this
 */
proto.hotel.Company.prototype.setTaxCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.hotel.Company.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Company} returns this
 */
proto.hotel.Company.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Receiver.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Receiver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Receiver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Receiver.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    note: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Receiver}
 */
proto.hotel.Receiver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Receiver;
  return proto.hotel.Receiver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Receiver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Receiver}
 */
proto.hotel.Receiver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Receiver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Receiver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Receiver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Receiver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.hotel.Receiver.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Receiver} returns this
 */
proto.hotel.Receiver.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.hotel.Receiver.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Receiver} returns this
 */
proto.hotel.Receiver.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.hotel.Receiver.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Receiver} returns this
 */
proto.hotel.Receiver.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.hotel.Receiver.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Receiver} returns this
 */
proto.hotel.Receiver.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string note = 5;
 * @return {string}
 */
proto.hotel.Receiver.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Receiver} returns this
 */
proto.hotel.Receiver.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.InvoicingInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.InvoicingInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.InvoicingInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.InvoicingInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyInfo: (f = msg.getCompanyInfo()) && proto.hotel.Company.toObject(includeInstance, f),
    receiverInfo: (f = msg.getReceiverInfo()) && proto.hotel.Receiver.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.InvoicingInformation}
 */
proto.hotel.InvoicingInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.InvoicingInformation;
  return proto.hotel.InvoicingInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.InvoicingInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.InvoicingInformation}
 */
proto.hotel.InvoicingInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.Company;
      reader.readMessage(value,proto.hotel.Company.deserializeBinaryFromReader);
      msg.setCompanyInfo(value);
      break;
    case 2:
      var value = new proto.hotel.Receiver;
      reader.readMessage(value,proto.hotel.Receiver.deserializeBinaryFromReader);
      msg.setReceiverInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.InvoicingInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.InvoicingInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.InvoicingInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.InvoicingInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hotel.Company.serializeBinaryToWriter
    );
  }
  f = message.getReceiverInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.Receiver.serializeBinaryToWriter
    );
  }
};


/**
 * optional Company company_info = 1;
 * @return {?proto.hotel.Company}
 */
proto.hotel.InvoicingInformation.prototype.getCompanyInfo = function() {
  return /** @type{?proto.hotel.Company} */ (
    jspb.Message.getWrapperField(this, proto.hotel.Company, 1));
};


/**
 * @param {?proto.hotel.Company|undefined} value
 * @return {!proto.hotel.InvoicingInformation} returns this
*/
proto.hotel.InvoicingInformation.prototype.setCompanyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.InvoicingInformation} returns this
 */
proto.hotel.InvoicingInformation.prototype.clearCompanyInfo = function() {
  return this.setCompanyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.InvoicingInformation.prototype.hasCompanyInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Receiver receiver_info = 2;
 * @return {?proto.hotel.Receiver}
 */
proto.hotel.InvoicingInformation.prototype.getReceiverInfo = function() {
  return /** @type{?proto.hotel.Receiver} */ (
    jspb.Message.getWrapperField(this, proto.hotel.Receiver, 2));
};


/**
 * @param {?proto.hotel.Receiver|undefined} value
 * @return {!proto.hotel.InvoicingInformation} returns this
*/
proto.hotel.InvoicingInformation.prototype.setReceiverInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.InvoicingInformation} returns this
 */
proto.hotel.InvoicingInformation.prototype.clearReceiverInfo = function() {
  return this.setReceiverInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.InvoicingInformation.prototype.hasReceiverInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.hotel);
